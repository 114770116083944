import Layout from "@organisms/Layout";
import { graphql } from "gatsby";
import { Helmet, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import { FaEnvelope, FaPhone, FaGlobe } from "react-icons/fa";
const ImpressumPage = () => {
  const { t } = useTranslation("impressum");

  return (
    <Layout>
      <Helmet>
        <title>BARAKA | {t("Impressum")}</title>
      </Helmet>

      <main
        style={{
          maxWidth: 1500,
        }}
        className="px-5 mx-auto mt-20"
      >
        <h1 className="mt-40 mb-5">{t("Impressum")}</h1>

        <div className="my-5">
          <p>BARAKA GmbH</p>
          <p>Kerpener Straße 60</p>
          <p>50170 Kerpen</p>
          <p>{t("Deutschland")}</p>
        </div>
        <div className="my-5">
          <p>Vertreten durch:</p>
          <p>BARAKA GmbH wird vertreten durch Faissal Bousfia</p>
        </div>
        <div className="my-5">
          <p>Register und Registernummer: AG Köln HRB 110480</p>
          <p>USt-Id.Nr.: DE352310710</p>
        </div>

        <div className="mb-10">
          <div className="flex items-center mb-2">
            <FaEnvelope className="mr-3 text-xl" />
            <a
              href="mailto:f.bousfia@baraka-grosshandel.de"
              className="underline text-blue-800"
            >
              info@baraka-grosshandel.de
            </a>
          </div>

          <div className="flex items-center mb-2">
            <FaPhone className="mr-3 text-xl" />
            <p> +49 (0)2273 / 95 36 450</p>
          </div>

          <div className="flex items-center mb-2">
            <FaGlobe className="mr-3 text-xl" />
            <a
              className="underline text-blue-800"
              href="https://www.baraka-grosshandel.de"
              target="__blank"
            >
              www.baraka-grosshandel.de
            </a>
          </div>

          <p className="break-words">
            {t("Plattform der EU-Kommission zur Online-Streitbeilegung:")}{" "}
            <a
              className="underline text-blue-800"
              href="https://ec.europa.eu/consumers/odr"
              target="__blank"
            >
              https://ec.europa.eu/consumers/odr
            </a>
          </p>
        </div>

        <p className="mb-20">
          {t(
            "Wir sind zur Teilnahme an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle weder verpflichtet noch bereit."
          )}
        </p>
      </main>
    </Layout>
  );
};

export default ImpressumPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["header", "impressum", "footer"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
